import { Link } from "@mui/material";
import React from "react";

import { useTranslation } from "react-i18next";
import { Link as ReactLink } from "react-router-dom";

export default function CommonLink({ to, label, ...other }) {
  const { t } = useTranslation();
  return (
    <Link underline="hover" component={ReactLink} to={to} {...other}>
      {t(label)}
    </Link>
  );
}

export function ExternalLink({ to, label, ...other }) {
  const { t } = useTranslation();
  return (
    <Link underline="hover" href={to} {...other}>
      {t(label)}
    </Link>
  );
}
