import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";

import RegionIcon from "../components/RegionIcon";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useTranslation } from "react-i18next";

function Documentation() {
  const { t } = useTranslation();

  return (
    <Box
      color="text.primary"
      sx={{
        p: 3,
        mx: 0,
        mb: 3,
        border: 1,
        borderColor: "other.orange",
        borderRadius: 1,
      }}
    >
      <PictureAsPdfIcon sx={{ verticalAlign: "bottom" }} />
      <Link
        component="a"
        sx={{ mx: 2 }}
        color="text.primary"
        href="/data/navius_research_gtech_iesd_model_documentation.pdf"
        download="navius_research_gtech_iesd_model_documentation.pdf"
      >
        {t("modeldocumentation")}
      </Link>
    </Box>
  );
}

export default function DownloadDialog({
  open,
  setOpen,
  regionList,
}) {
  const { t, i18n } = useTranslation();
  const handleClose = () => setOpen(false);

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogContent>
          <Documentation />
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ textAlign: "center", pb: 2 }}>
              <Typography variant="h6">{t("download_regional_data")}</Typography>
            </Grid>
            {regionList.map(r => {
              const target = `/data/${i18n.resolvedLanguage}/${r.regional_data}`;
              return (
                <Grid key={r.label} item xs={6} sm={3}>
                  <Link
                    component="a"
                    color="text.primary"
                    href={target}
                    download={r.regional_data}>
                    <Box sx={{ textAlign: "center" }}>
                      <Box >
                        <RegionIcon region={r.to} large={true}></RegionIcon>
                      </Box>
                      {t(r.label)}
                    </Box>
                  </Link>
                </Grid>
              )
            })}
            <Grid item xs={12}>
              <Link
                component="a"
                color="text.primary"
                href={`/data/${i18n.resolvedLanguage}/navius_research_regional_data_all.zip`}
                download={"navius_research_regional_data_all.zip"}>
                <Box sx={{ textAlign: "center", pt: 2 }}>
                  <Typography variant="h6">{t("download_all_data")}</Typography>
                </Box>
              </Link>
            </Grid>
          </Grid>
        </DialogContent>
    </Dialog>
  );
}
