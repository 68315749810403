import "../components/Map.css";

import CommonTooltip from "./CommonTooltip";
import { ReactComponent as MapleLeafIcon } from "../assets/images/icons/mapleleaf.svg";
import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ParamsContext } from "../contexts/ParamsContext";
import { ViewContext } from "../contexts/ViewContext";
import { RegionContext } from "../contexts/RegionContext";

export default function SelectCanadaButton() {
  const { t } = useTranslation();
  const { getUrl } = useContext(ParamsContext);
  const navigate = useNavigate();

  const { view } = useContext(ViewContext);
  const { region } = useContext(RegionContext);

  const destinationView = useMemo(
    () => (view === "map" || view === null ? "electricity" : view),
    [view]
  );

  const handleOnClick = () => {
    navigate(
      getUrl({
        view: destinationView,
        region: "Canada",
      })
    );
  };

  return (
    <CommonTooltip title={t("region_canada_tooltip")} placement="bottom">
      <MapleLeafIcon
        className={region === "Canada" ? "selectedRegion" : "region"}
        onClick={handleOnClick}
      />
    </CommonTooltip>
  );
}
