import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Map from "./Map";
import LanguageButton from "../common/LanguageButton";

export default function HomePage() {
  const { t } = useTranslation();
  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid item md={3} xs={12} sx={{ textAlign: "center", py: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ px: 5 }}>
            <Typography variant="h6">{t("home_select_language")}</Typography>
          </Box>
          <LanguageButton />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ p: 1 }}>
            <Typography variant="body">{t("welcometext")}</Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </Box>
      </Grid>
      <Grid item md={6} xs={12} sx={{ py: 6 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ py: 2, textAlign: "center" }}>
            <Typography variant="h4">{t("home_select_region")}</Typography>
          </Box>
          <Map width="100%" height="50vh" />
          <Box sx={{ flexGrow: 1 }} />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}></Grid>
    </Grid>
  );
}
