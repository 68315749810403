import { Box, Button, CssBaseline, Drawer, Typography } from "@mui/material";

import { Global } from "@emotion/react";
import PolicyBox from "../common/PolicyBox";
import { ParamsContext } from "../contexts/ParamsContext";
import React, { useContext } from "react";
import SlidersBox from "../common/SlidersBox";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

const drawerHeight = 56;

export default function MobileSettings({
  isSettingsOpen,
  policyList,
  sliders,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getUrl } = useContext(ParamsContext);

  const mobileView = true;

  return (
    <div>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(46% - ${drawerHeight}px)`,
            overflow: "visible",
            border: "none",
          },
        }}
      />
      <Drawer
        anchor="bottom"
        hideBackdrop
        open={isSettingsOpen}
        variant="persistent"
        keepMounted={true}
      >
        <Button
          disableElevation
          variant="contained"
          color="secondary"
          sx={{
            position: "absolute",
            top: -drawerHeight,
            borderRadius: 0,
            visibility: "visible",
            width: "100%",
            right: 0,
            left: 0,
            p: 0,
            m: 0,
          }}
          onClick={() =>
            navigate(
              getUrl({
                settings: isSettingsOpen ? "closed" : "open",
              })
            )
          }
        >
          <Typography
            variant="body"
          >
            {t("controls")}
          </Typography>
        </Button>
        <Box
          sx={{
            p: 2,
            pt: 3,
            height: "100%",
            overflow: "auto",
            backgroundColor: "other.grey6",
            color: "other.white",
          }}
        >
          <PolicyBox policyList={policyList} mobileView={mobileView} />

          <SlidersBox sliders={sliders} />
        </Box>
      </Drawer>
    </div>
  );
}
