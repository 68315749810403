import React, { useContext, useMemo } from "react";

import { SvgIcon } from "@mui/material";

import { ReactComponent as Atlantic } from "../assets/images/regions/Atlantic.svg";
import { ReactComponent as AB } from "../assets/images/regions/AB.svg";
import { ReactComponent as BC } from "../assets/images/regions/BC.svg";
import { ReactComponent as CANADA } from "../assets/images/regions/BlackMapWithNoTitles.svg";
import { ReactComponent as MN } from "../assets/images/regions/MN.svg";
import { ReactComponent as NB } from "../assets/images/regions/NB.svg";
import { ReactComponent as NFL } from "../assets/images/regions/NFL.svg";
import { ReactComponent as NS } from "../assets/images/regions/NS.svg";
import { ReactComponent as NV } from "../assets/images/regions/NV.svg";
import { ReactComponent as NWT } from "../assets/images/regions/NWT.svg";
import { ReactComponent as ONT } from "../assets/images/regions/ONT.svg";
import { ReactComponent as PEI } from "../assets/images/regions/PEI.svg";
import { ReactComponent as QB } from "../assets/images/regions/QB.svg";
import { ReactComponent as SK } from "../assets/images/regions/SK.svg";
import { ReactComponent as YK } from "../assets/images/regions/YK.svg";

import { RegionContext } from "../contexts/RegionContext";

export function AutomaticRegionIcon({ large = false }) {
  const { region } = useContext(RegionContext);
  const chosen = useMemo(() => {
    return region ? region : "Canada";
  }, [region]);
  return <RegionIcon region={chosen} large={large} />;
}

export default function RegionIcon({ region, large = false }) {
  let comp;
  switch (region) {
    case "BC":
      comp = BC;
      break;
    case "AB":
      comp = AB;
      break;
    case "MB":
      comp = MN;
      break;
    case "SK":
      comp = SK;
      break;
    case "ON":
      comp = ONT;
      break;
    case "QC":
      comp = QB;
      break;
    case "PE":
      comp = PEI;
      break;
    case "NS":
      comp = NS;
      break;
    case "NB":
      comp = NB;
      break;
    case "NL":
      comp = NFL;
      break;
    case "YT":
      comp = YK;
      break;
    case "NT":
      comp = NWT;
      break;
    case "NU":
      comp = NV;
      break;
    case "RC":
      comp = Atlantic;
      break;
    case "CANADA":
    default:
      comp = CANADA;
      break;
  }
  const extraParams = large ? { fontSize: "large" } : {};
  return <SvgIcon component={comp} inheritViewBox {...extraParams} />;
}
