import { Button, Typography } from "@mui/material";

import FooterBar from "./FooterBar";
import LinkPopups from "../common/LinkPopups";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PopupButtons({ items }) {
  const { t } = useTranslation();

  return items.map((item) => (
    <Button
      key={item.label}
      sx={[
        {
          color: !item.active ? "other.black" : "other.white",
          bgcolor: !item.active ? "other.grey2" : "other.orange",
          px: 1,
          py: 0,
          mx: 0,
          borderRadius: 0,
        },
        {
          ":hover": {
            color: "other.black",
            bgcolor: "other.orange",
          },
        },
      ]}
      component={Link}
      to={item.url}
    >
      {t(item.label)}
    </Button>
  ));
}

export default function Footer({ links, downloadButton }) {
  const { t } = useTranslation();
  return (
    <FooterBar downloadButton={downloadButton}>
      {links.map((link) => (
        <LinkPopups
          key={link.name}
          buttons={<PopupButtons items={link.list} />}
        >
          {(popupProps) => (
            <Button
              {...popupProps}
              key={link.name}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "secondary.contrastText",
              }}
            >
              {link.list?.find((item) => item.active)?.icon}
              <Typography sx={{ mx: 0.5 }}>
                {t(link.list?.find((item) => item.active)?.label)}
              </Typography>
            </Button>
          )}
        </LinkPopups>
      ))}
    </FooterBar>
  );
}
