import { Box, IconButton } from "@mui/material";
import React, { useContext } from "react";

import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { ColorModeContext } from "../Contexts.js";
import { useTheme } from "@mui/styles";

export default function ThemeButton() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "inline-flex",
        border: "0.1px solid grey",
        borderRadius: 2,
        mx: 0.5,
      }}
    >
      <IconButton
        sx={{
          color: "inherit",
          p: 0,
        }}
        onClick={useContext(ColorModeContext).toggleColorMode}
      >
        {theme.palette.mode === "dark" ? (
          <Brightness7Icon />
        ) : (
          <Brightness4Icon />
        )}
      </IconButton>
    </Box>
  );
}
