import "./i18n";

import { ColorModeContext, DeviceContext } from "./Contexts.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  graphDataUrl,
  graphMap,
  policyList,
  regionList,
  sliders,
  sliderGroups,
  viewList,
} from "./Data";

import { Helmet } from "react-helmet";
import Header from "./components/Header.js";
import HomePage from "./components/HomePage";
import ViewPage from "./components/ViewPage";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { ParamsContext } from "./contexts/ParamsContext";
import { PolicyContext } from "./contexts/PolicyContext";
import { RegionContext } from "./contexts/RegionContext";
import { ViewContext } from "./contexts/ViewContext";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    contrastThreshold: 5,
    tonalOffset: 0.5,
    ...(mode === "dark"
      ? {
          primary: {
            main: "#333333",
            light: "#5c5c5c",
            dark: "#0c0c0c",
            contrastText: "#ffffff",
          },
          secondary: {
            main: "#2e606a",
            light: "#5c8d98",
            dark: "#00363f",
            contrastText: "#ffffff",
          },
        }
      : {
          primary: {
            main: "#fdfdfd",
            light: "#ffffff",
            dark: "#cacaca",
            contrastText: "#000000",
          },
          secondary: {
            main: "#2e606a",
            light: "#5c8d98",
            dark: "#00363f",
            // light: "#5c5c5c",
            // dark: "#0c0c0c",
            contrastText: "#ffffff",
          },
        }),
    other: {
      red: "#f15a29",
      lightorange: "#faa81961",
      orange: "#faa819",
      grey1: "#e7e7e7",
      grey2: "#cdcdcd",
      grey3: "#b4b4b4",
      grey4: "#9a9a9a",
      grey5: "#818181",
      grey6: "#454545",
      black: "#000000",
      white: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      narrow300: 300,
      narrow400: 400,
      narrow500: 500,
    },
  },
});

const headerHeight = "128px";

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(window.navigator.language);
  }, [i18n]);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const contextView = useMemo(() => {
    const view = searchParams.get("view");
    return view || null;
  }, [searchParams]);

  const contextRegion = useMemo(() => {
    const region = searchParams.get("region");
    return region || null;
  }, [searchParams]);

  const contextPolicy = useMemo(() => {
    const policy = searchParams.get("policy");
    return policy || null;
  }, [searchParams]);

  const controlParams = useMemo(() => {
    const paramValues = Object.entries(sliders).reduce((acc, entry) => {
      const [paramName, slider] = entry;
      acc[paramName] = searchParams.get(paramName) || slider.default;
      return acc;
    }, {});
    return paramValues;
  }, [searchParams]);

  const params = useMemo(() => {
    const policy = searchParams.get("policy");
    const region = searchParams.get("region");
    const view = searchParams.get("view");
    const settings = searchParams.get("settings");

    return {
      policy: policy || "current",
      region: region || "Canada",
      view: view || "map",
      settings: settings || "open",
      ...controlParams,
    };
  }, [controlParams, searchParams]);

  const expandedGraphDataUrl = useCallback(
    (graphId) => {
      return graphDataUrl(graphId, contextPolicy, contextRegion, controlParams);
    },
    [contextPolicy, contextRegion, controlParams]
  );

  const getUrl = useCallback(
    (changes) => {
      return `/view.html?${new URLSearchParams({
        ...params,
        ...changes,
      }).toString()}`;
    },
    [params]
  );

  const setParam = useCallback(
    (code, newValue) => {
      navigate(
        getUrl({
          [`p_${code}`]: [newValue], // TODO update to not assume p_ prefix
        })
      );
    },
    [getUrl, navigate]
  );

  const getIntParam = useCallback(
    (code) => {
      return parseInt(params[[`p_${code}`]], 10);
    },
    [params]
  );

  const isSettingsOpened = params.settings === "open";

  return (
    <ParamsContext.Provider value={{ getUrl, setParam, getIntParam }}>
      <ViewContext.Provider value={{ view: contextView }}>
        <RegionContext.Provider value={{ region: contextRegion }}>
          <PolicyContext.Provider value={{ policy: contextPolicy }}>
            <div>
              <Helmet>
                <title>{t("heading")}</title>
                <meta name="description" content={t("heading")} />
              </Helmet>
              <Header
                graphMap={graphMap}
                viewList={viewList}
                regionList={regionList}
                headerHeight={headerHeight}
              />
              <Routes>
                <Route path="/index.html" element={<HomePage />}></Route>
                <Route
                  path="/view.html"
                  element={
                    <ViewPage
                      graphMap={graphMap}
                      graphDataUrl={expandedGraphDataUrl}
                      policyList={policyList}
                      viewList={viewList}
                      regionList={regionList}
                      sliders={sliderGroups}
                      headerHeight={headerHeight}
                      isSettingsOpened={isSettingsOpened}
                    />
                  }
                ></Route>
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <p>{t("errormessage")}</p>
                    </main>
                  }
                />
              </Routes>
            </div>
          </PolicyContext.Provider>
        </RegionContext.Provider>
      </ViewContext.Provider>
    </ParamsContext.Provider>
  );
}

export default function AppWrapper() {
  const device = useMediaQuery("(max-width:1020px)");
  const prefersDark = useMediaQuery("(prefers-color-scheme: dark)");

  const [mode, setMode] = useState(prefersDark ? "dark" : "light");

  useEffect(() => {
    setMode(prefersDark ? "dark" : "light");
  }, [prefersDark]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <DeviceContext.Provider value={device}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </DeviceContext.Provider>
  );
}
