import { Button, Grid, Typography } from "@mui/material";

import React, { useContext } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import CommonTooltip from "./CommonTooltip";
import { ParamsContext } from "../contexts/ParamsContext";
import { PolicyContext } from "../contexts/PolicyContext";

export default function PolicyBox({ policyList, mobileView }) {
  return mobileView ? (
    <MobilePolicyBox policyList={policyList} />
  ) : (
    <DesktopPolicyBox policyList={policyList} />
  );
}

function MobilePolicyBox({ policyList }) {
  const tallScreen = useMediaQuery("(min-height:1000px)");
  const theme = useTheme();
  const { t } = useTranslation();
  const { getUrl } = useContext(ParamsContext);
  const { policy: currentPolicy } = useContext(PolicyContext);
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction={"column"}
      sx={{
        textAlign: "center",
        color: "secondary.contrastText",
      }}
    >
      <Grid
        item
        sx={{
          m: 0,
          alignSelf: "center",
        }}
      >
        <Typography
          color="other.orange"
          align="left"
          variant="body"
        >
          {t("policy")}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography
          align="center"
          variant="body"
        >
          {t("policy_instructions")}
        </Typography>
      </Grid>
      <Grid item sx={{ my: 1, textAlign: "center" }}>
        {policyList.map((policy) => (
          <Button
            key={policy.to}
            variant="contained"
            sx={{
              px: 2,
              mx: 1,
              borderRadius: 6,
              fontSize: tallScreen ? "1rem" : "0.75rem",
              "&.MuiButtonBase-root": {
                bgcolor:
                  currentPolicy !== policy.to ? "other.grey2" : "other.orange",
                color:
                  currentPolicy !== policy.to ? "other.black" : "other.white",
                "&&:hover": {
                  bgcolor:
                    currentPolicy !== policy.to
                      ? "other.grey2"
                      : "other.orange",
                  color:
                    currentPolicy !== policy.to ? "other.black" : "other.white",
                },
              },
            }}
            onClick={() =>
              navigate(
                getUrl({
                  policy: [policy.to],
                })
              )
            }
          >
            {t(policy.label)}
          </Button>
        ))}
      </Grid>
    </Grid>
  );
}

function DesktopPolicyBox({ policyList }) {
  const tallScreen = useMediaQuery("(min-height:1000px)");
  const theme = useTheme();
  const { t } = useTranslation();
  const { getUrl } = useContext(ParamsContext);
  const navigate = useNavigate();
  const { policy: currentPolicy } = useContext(PolicyContext);

  return (
    <Grid container sx={{ my: 1, px: 1, justifyContent: "center" }}>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography align="center" variant="h8">
          {t("policy_instructions")}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ justifyContent: "center", textAlign: "center" }}>
        {policyList.map((policy) => (
          <CommonTooltip key={policy.to} title={t(policy.tooltip)}>
            <Button
              variant="contained"
              sx={[
                {
                  px: 3,
                  m: 1,
                  borderRadius: 6,
                  "&.MuiButtonBase-root": {
                    bgcolor:
                      currentPolicy !== policy.to
                        ? "other.grey2"
                        : "other.orange",
                    color:
                      currentPolicy !== policy.to
                        ? "other.black"
                        : "other.white",
                    "&&:hover": {
                      bgcolor:
                        currentPolicy !== policy.to
                          ? "other.grey2"
                          : "other.orange",
                      color:
                        currentPolicy !== policy.to
                          ? "other.black"
                          : "other.white",
                    },
                  },
                  fontSize: tallScreen ? "1rem" : "0.75rem",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "0.75rem",
                  },
                  [theme.breakpoints.down("narrowMobile")]: {
                    fontSize: "0.5rem",
                  },
                },
              ]}
              onClick={() => navigate(getUrl({ policy: [policy.to] }))}
            >
              {t(policy.label)}
            </Button>
          </CommonTooltip>
        ))}
      </Grid>
    </Grid>
  );
}
