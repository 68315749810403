import PopupState, { bindFocus, bindPopover } from "material-ui-popup-state";

import { Popover } from "@mui/material";
import React from "react";

export default function LinkPopups({ buttons = [], children }) {
  return (
    <PopupState variant="popover" popupId="link-popups">
      {(popupState) => (
        <>
          {children({ ...bindFocus(popupState) })}
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            PaperProps={{
              sx: { display: "grid" },
            }}
          >
            {buttons}
          </Popover>
        </>
      )}
    </PopupState>
  );
}
