import { Box, Grid, Typography } from "@mui/material";

import CommonDivider from "../common/CommonDivider";
import Map from "./Map";
import PolicyBox from "../common/PolicyBox";
import React, { useContext } from "react";
import SlidersBox from "../common/SlidersBox";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ViewContext } from "../contexts/ViewContext";

export default function Settings({
  policyList,
  sliders,
  footerHeight,
  mapBoxHeight,
}) {
  const { view } = useContext(ViewContext);
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <>
      <Grid
        item
        sx={{
          display: "flex",
          boxSizing: "border-box", //If you set box-sizing: border-box; on an element, padding and border are included in the width and height:
          alignItems: "center",
          justifyContent: "center",
          height: mapBoxHeight,
        }}
      >
        {view === "map" ? (
          <Typography
            variant="body1"
            align="center"
            sx={{ wordWrap: "break-word" }}
          >
            {t("welcometext")}
          </Typography>
        ) : (
          <Map width={"100%"} height={"100%"} strokeWidth={"0.5"} />
        )}
      </Grid>
      <CommonDivider sx={{ mt: 2.5 }}>
        <Typography
          align="center"
          variant="h6"
          sx={{
            m: 1,
            [theme.breakpoints.down("lg")]: {
              fontSize: "1rem",
            },
          }}
        >
          {t("policy")}
        </Typography>
      </CommonDivider>
      <PolicyBox policyList={policyList} />
      <CommonDivider>
        <Typography
          align="center"
          variant="h6"
          sx={{
            m: 1,
            [theme.breakpoints.down("lg")]: {
              fontSize: "1rem",
            },
          }}
        >
          {t("slidersheading")}
        </Typography>
      </CommonDivider>
      <Box sx={{ m: 1 }}>
        <SlidersBox sliders={sliders} />
      </Box>
      <Box sx={{ height: footerHeight, bottom: 0 }} />
    </>
  );
}
