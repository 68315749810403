import { ReactComponent as AB } from "./assets/images/regions/AB.svg";
import { ReactComponent as BC } from "./assets/images/regions/BC.svg";
import { ReactComponent as CANADA } from "./assets/images/regions/BlackMapWithNoTitles.svg";
import { ReactComponent as ECONOMY } from "./assets/images/icons/icon_economy.svg";
import { ReactComponent as ELECTRICITY } from "./assets/images/icons/icon_electricity.svg";
import { ReactComponent as ENERGY } from "./assets/images/icons/icon_energy.svg";
import { ReactComponent as MN } from "./assets/images/regions/MN.svg";
import { ReactComponent as NB } from "./assets/images/regions/NB.svg";
import { ReactComponent as NFL } from "./assets/images/regions/NFL.svg";
import { ReactComponent as NS } from "./assets/images/regions/NS.svg";
import { ReactComponent as NV } from "./assets/images/regions/NV.svg";
import { ReactComponent as NWT } from "./assets/images/regions/NWT.svg";
import { ReactComponent as ONT } from "./assets/images/regions/ONT.svg";
import { ReactComponent as PEI } from "./assets/images/regions/PEI.svg";
import { ReactComponent as QB } from "./assets/images/regions/QB.svg";
import { ReactComponent as Atlantic } from "./assets/images/regions/Atlantic.svg";
import React from "react";
import { ReactComponent as SK } from "./assets/images/regions/SK.svg";
import { SvgIcon } from "@mui/material";
import { ReactComponent as TECHNOLOGY } from "./assets/images/icons/icon_technology.svg";
import { ReactComponent as YK } from "./assets/images/regions/YK.svg";

import { AutomaticRegionIcon } from "./components/RegionIcon.js";

const viewList = [
  {
    label: " ", //space needed for colors to work
    color: "other.white",
    to: "map",
    icon: <AutomaticRegionIcon large={false} />,
    largeIcon: <AutomaticRegionIcon large={true} />,
    dashboardItem: false,
    regional: true,
  },
  {
    label: "view_electricity",
    color: "other.grey1",
    to: "electricity",
    icon: <SvgIcon component={ELECTRICITY} inheritViewBox />,
    largeIcon: (
      <SvgIcon component={ELECTRICITY} inheritViewBox fontSize="large" />
    ),
    dashboardItem: true,
  },
  {
    label: "view_energy_and_emissions",
    color: "other.grey2",
    to: "energy",
    icon: <SvgIcon component={ENERGY} inheritViewBox />,
    largeIcon: <SvgIcon component={ENERGY} inheritViewBox fontSize="large" />,
    dashboardItem: true,
  },
  {
    label: "view_technology",
    color: "other.grey3",
    to: "technology",
    icon: <SvgIcon component={TECHNOLOGY} inheritViewBox />,
    largeIcon: (
      <SvgIcon component={TECHNOLOGY} inheritViewBox fontSize="large" />
    ),
    dashboardItem: true,
  },
  {
    label: "view_economy",
    color: "other.grey4",
    to: "economy",
    icon: <SvgIcon component={ECONOMY} inheritViewBox />,
    largeIcon: <SvgIcon component={ECONOMY} inheritViewBox fontSize="large" />,
    dashboardItem: true,
  },
];

const regionList = [
  {
    label: "region_canada",
    class: "Canada clip",
    to: "Canada",
    icon: <SvgIcon component={CANADA} inheritViewBox />,
    regional_data: "navius_research_regional_data_can.zip",
  },
  {
    label: "region_bc",
    class: "BC clip",
    to: "BC",
    icon: <SvgIcon component={BC} inheritViewBox />,
    regional_data: "navius_research_regional_data_bc.zip",
  },
  {
    label: "region_ab",
    class: "AB clip",
    to: "AB",
    icon: <SvgIcon component={AB} inheritViewBox />,
    regional_data: "navius_research_regional_data_ab.zip",
  },
  {
    label: "region_sk",
    class: "SK clip",
    to: "SK",
    icon: <SvgIcon component={SK} inheritViewBox />,
    regional_data: "navius_research_regional_data_sk.zip",
  },
  {
    label: "region_mb",
    class: "MB clip",
    to: "MB",
    icon: <SvgIcon component={MN} inheritViewBox />,
    regional_data: "navius_research_regional_data_mb.zip",
  },
  {
    label: "region_on",
    class: "ON clip",
    to: "ON",
    icon: <SvgIcon component={ONT} inheritViewBox />,
    regional_data: "navius_research_regional_data_on.zip",
  },
  {
    label: "region_qc",
    class: "QC clip",
    to: "QC",
    icon: <SvgIcon component={QB} inheritViewBox />,
    regional_data: "navius_research_regional_data_qc.zip",
  },
  {
    label: "region_atlantic",
    class: "NB clip",
    to: "RC",
    icon: <SvgIcon component={Atlantic} inheritViewBox />,
    regional_data: "navius_research_regional_data_rc.zip",
  },
];

const policyList = [
  {
    label: "policy_label_current",
    tooltip: "policy_tooltip_current",
    to: "current",
  },
  {
    label: "policy_label_netzero",
    tooltip: "policy_tooltip_netzero",
    to: "netzero",
  },
];

const sliders = {
  p_wss: {
    group: 1,
    order: 1,
    code: "wss",
    label: "slider_label_wss",
    tooltip: "slider_tooltip_wss",
    max: 2,
    default: 1,
    icon: "/images/icons/icon_solar.svg",
    hoverTexts: [
      "slider_dollar1",
      "slider_dollar2",
      "slider_dollar3",
      "slider_dollar4",
      "slider_dollar5",
    ],
  },
  p_hyd: {
    group: 1,
    order: 3,
    code: "hyd",
    label: "slider_label_hyd",
    tooltip: "slider_tooltip_hyd",
    max: 2,
    default: 1,
    icon: "/images/icons/icon_hydrogen.svg",
    hoverTexts: [
      "slider_dollar1",
      "slider_dollar2",
      "slider_dollar3",
      "slider_dollar4",
      "slider_dollar5",
    ],
  },
  p_dac: {
    group: 3,
    order: 5,
    code: "dac",
    label: "slider_label_dac",
    tooltip: "slider_tooltip_dac",
    max: 1,
    default: 0,
    icon: "/images/icons/icon_dac.svg",
    hoverTexts: [
      { text: "slider_not_available", disabled: true },
      "slider_available",
    ],
  },
  p_ccs: {
    group: 1,
    order: 2,
    code: "ccs",
    label: "slider_label_ccs",
    tooltip: "slider_tooltip_ccs",
    max: 2,
    default: 1,
    icon: "/images/icons/icon_carbon_cap.svg",
    hoverTexts: [
      "slider_dollar1",
      "slider_dollar2",
      "slider_dollar3",
      "slider_dollar4",
      "slider_dollar5",
    ],
  },
  p_oil: {
    group: 2,
    order: 4,
    code: "oil",
    label: "slider_label_oil",
    tooltip: "slider_tooltip_oil",
    max: 1,
    default: 1,
    icon: "/images/icons/icon_oil.svg",
    hoverTexts: ["slider_dollar1", "slider_dollar2"],
  },
  p_lul: {
    group: 3,
    order: 6,
    code: "lul",
    label: "slider_label_lul",
    tooltip: "slider_tooltip_lul",
    max: 1,
    default: 1,
    icon: "/images/icons/icon_forestry.svg",
    hoverTexts: ["slider_low", "slider_high"],
  },
};

const sortedSliders = Object.values(sliders).reduce((acc, slider) => {
  if (!acc.hasOwnProperty(slider.group)) {
    acc[slider.group] = [slider];
  } else {
    acc[slider.group].push(slider);
    acc[slider.group].sort((a, b) => {
      return a.order - b.order;
    });
  }
  return acc;
}, {});

const sliderGroups = [
  "sliders_subheading_cost",
  "sliders_subheading_oil",
  "sliders_subheading_emission",
].map((value, idx) => {
  return { label: value, sliders: sortedSliders[idx + 1] };
});

const graphRoot = process.env?.REACT_APP_DATA_URL || "/graph";

function prepareParams(params) {
  const plucked = Object.entries(params).reduce((acc, entry) => {
    if (!sliders.hasOwnProperty(entry[0])) {
      throw new Error(`Unrecognized parameter: ${entry[0]}`);
    }
    const found = parseInt(entry[1], 10);
    if (!Number.isInteger(found)) {
      throw new Error(`Invalid parameter value: ${entry[0]} = ${entry[1]}`);
    }
    const slider = sliders[entry[0]];
    acc.push([slider.code, found]);
    return acc;
  }, []);
  return plucked.sort().flat().join("/");
}

function graphDataUrl(graphId, policy, region, params) {
  if (!policy || !region) {
    throw new Error("Policy and region are required when fetching data!");
  }
  const concatenatedParams = prepareParams(params);

  return `${graphRoot}/${graphId}/${policy}/${region}/${concatenatedParams}/data.json`;
}

const graphMap = {
  electricity: [
    "electricity-demand-by-sector",
    "electricity-capacity-by-source",
    "electricity-generation-by-source",
    "electricity-price",
    "electricity-storage",
    "electricity-peak-supply",
  ],
  energy: [
    "energy-emissions-by-sector",
    "energy-consumption-by-sector",
    "energy-consumption-by-fuel",
    "energy-change-in-demand",
    "energy-production",
    "share-clean-energy",
  ],
  technology: [
    "market-share-vehicles",
    "market-share-building-heating",
    "carbon-capture",
    "hydrogen-by-sector",
    "hydrogen-production",
    "biofuel-demand",
  ],
  economy: [
    "GDP-by-sector",
    "GDP-growth-rate",
    "green-economy-growth-rate",
    "jobs-by-sector",
    "green-economy-GDP",
    "green-economy-investment",
  ],
};
export {
  graphMap,
  graphDataUrl,
  policyList,
  regionList,
  viewList,
  sliders,
  sliderGroups,
};
