import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";

import { useTranslation } from "react-i18next";

export default function LanguageButton() {
  const { i18n } = useTranslation();

  const handleToggle = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        border: "0.1px solid grey",
        borderRadius: 2,
        display: "inline-flex",
        mx: 0.5,
      }}
    >
      <ToggleButtonGroup
        color="warning"
        exclusive
        onChange={(event) => handleToggle(event)}
        value={i18n.resolvedLanguage}
      >
        <ToggleButton
          sx={{
            py: 0,
            border: "none",
            color: "inherit",
          }}
          value="en"
        >
          EN
        </ToggleButton>
        <ToggleButton
          sx={{
            py: 0,
            borderRadius: 0,
            border: "none",
            color: "inherit",
          }}
          value="fr"
        >
          FR
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
