import { AppBar, Breadcrumbs, Grid, Toolbar } from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";
import { useTheme } from "@mui/material/styles";

function handleClick(event) {
  event.preventDefault();
}

export default function FooterBar({ children, downloadButton }) {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        top: "auto",
        bottom: 0,
        background: "transparent",
        boxShadow: "none",
      }}
    >
      <Toolbar disableGutters>
        <Grid
          item
          xs={10}
          sx={{
            bgcolor: "secondary.main",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: theme.spacing(0, 5, 5, 0),
          }}
        >
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs
              sx={{
                ml: 2,
                display: "flex",
                color: "secondary.contrastText",
              }}
              separator={<NavigateNextIcon fontSize="large" />}
            >
              {children}
            </Breadcrumbs>
          </div>
          {downloadButton}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
