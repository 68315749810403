import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import * as trans from "./translations.json"

i18n.use(initReactI18next).init({
  fallbackLng: ["en", "fr"],
  whitelist: ["en", "fr"],
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  react: {
    useSuspense: false,
  },
  ns: "default",
  defaultNS: "default",
  resources: trans
});

export default i18n;