import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";

export default function NaviusLogo({ primary, height, sx }) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const invertColor =
    !isDarkMode && primary
      ? {
          filter: "invert(1)",
        }
      : {};

  return (
    <Box
      sx={{
        ...(sx || {}),
        ...invertColor,
      }}
    >
      <a href="https://www.naviusresearch.com">
        <img
          src="/images/Navius-Logo-white.png"
          alt="Navius Research Logo"
          style={{
            maxHeight: height,
            width: "100%",
            objectFit: "contain",
          }}
        />
      </a>
    </Box>
  );
}
